import(/* webpackMode: "eager" */ "/app/app/_components/cookie-consent/CookieConsentBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/_components/StateWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/app/_images/bot_avatar.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/styles/global.scss");
