'use client';

import { RightOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import { ItemType, MenuItemType } from 'antd/es/menu/interface';
import cn from 'classnames';
import { useState } from 'react';

import { NavigationItem, NavigationItemChildrenM2M } from '@/app/_types/components/navigation-item';
import { ButtonM2A, NavigationItemM2M } from '@/app/_types/header';

import ButtonCustom from '../../button/ButtonCustom';

import Styles from './SubMenu.module.scss';

type MenuItem = Required<MenuProps>['items'][number];

type Props = {
  closeHamburger?: () => void;
  buttons?: ButtonM2A[] | null;
  items?: NavigationItemM2M[] | null;
};

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

export const SubMenu = ({ closeHamburger, buttons, items }: Props) => {
  const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([]);
  const [seeAllItems, setSeeAllItems] = useState<{ [key: string]: boolean }>(
    {},
  );

  const toggleSeeAll = (key: string) => {
    setSeeAllItems((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const transformMenuItem = (
    key: string,
    item: NavigationItem,
    closeHamburger: (() => void) | undefined,
    level: number = 1,
  ): MenuItem => {
    const { Title, navigation } = item;
    const href = navigation.permalink || '';
    const children =
      item.children && item.children.length > 0
        ? transformSubMenuData(item.children, closeHamburger, key, level + 1) ||
          undefined
        : undefined;

    return {
      key,
      label: (
        <div className={Styles.menuItem}>
          {href ? (
            <a
              href={href}
              className={Styles.menuLink}
              onClick={(e) => {
                e.stopPropagation();
                closeHamburger?.();
              }}
            >
              {Title}
            </a>
          ) : (
            <span className={Styles.menuText}>{Title}</span>
          )}
          {item.children && (
            <button
              className={'submenuToggle'}
              onClick={(e) => {
                e.stopPropagation();
                setStateOpenKeys((prev) =>
                  prev.includes(key)
                    ? prev.filter((k) => k !== key)
                    : [...prev, key],
                );
              }}
            >
              <RightOutlined />
            </button>
          )}
        </div>
      ),
      children: children,
    };
  };

  const transformMenuData = (
    items: NavigationItemM2M[],
    closeHamburger: (() => void) | undefined,
  ): ItemType<MenuItemType>[] => {
    return items.map((itemM2A) =>
      transformMenuItem(
        String(itemM2A.navigation_items_id.id),
        itemM2A.navigation_items_id,
        closeHamburger,
      ),
    );
  };

  const transformSubMenuData = (
    items: NavigationItemChildrenM2M[],
    closeHamburger: (() => void) | undefined,
    parentKey: string,
    level: number = 1,
  ): ItemType<MenuItemType>[] => {
    const showAll = seeAllItems[parentKey];
    const maxVisibleItems = showAll ? items.length : 7;
    const extraItems =
      level === 3 && items.length > 7
        ? [
            {
              key: `${parentKey}-see-all`,
              label: (
                <button
                  className={Styles.seeAllLink}
                  onClick={() => toggleSeeAll(parentKey)}
                >
                  {showAll ? 'See Less' : 'See All'}
                </button>
              ),
            },
          ]
        : [];

    return [
      ...items
        .slice(0, level === 3 ? maxVisibleItems : items.length)
        .map((item) =>
          transformMenuItem(
            String(item.related_navigation_items_id.id),
            item.related_navigation_items_id,
            closeHamburger,
            level,
          ),
        ),
      ...extraItems,
    ].filter(Boolean);
  };

  const menuItems: MenuItem[] = transformMenuData(items || [], closeHamburger);

  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {};
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };

    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(menuItems as LevelKeysProps[]);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys: string[]) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    );

    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <div className={Styles.subMenuWrapper}>
      <div className={Styles.subMenuContainer}>
        <Menu
          mode="inline"
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          items={menuItems}
          className={Styles.subMenuList}
          expandIcon={''}
        />
      </div>
      <ul className={Styles.actionList}>
        {buttons?.map((itemM2A, index) => {
          const item = itemM2A.item;
          const isSecondary = index % 2;

          return (
            <li key={itemM2A.id}>
              <ButtonCustom
                className={cn({ btnPrimaryAltOutline: isSecondary })}
                href={item.url ?? ''}
                btnText={item.text}
                fullWidth={true}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};
